html body#mine div._hj_feedback_container div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles div._hj-1Fv4C__Feedback__container div#_hj_feedback_container._hj-1qvel__Feedback__feedback div._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-uSKNz__MinimizedWidgetMiddle__left,
html body#mine div._hj_feedback_container div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles div._hj-1Fv4C__Feedback__container div#_hj_feedback_container._hj-1qvel__Feedback__feedback div._hj-XVgG4__ExpandedWidget__outerContainer._hj-ytB0a__ExpandedWidget__left._hj-2nT32__ExpandedWidget__middle._hj-IT3tA__ExpandedWidget__slideInLeft{
	top:unset !important;
	bottom:25px !important;
	transform:unset !important;
}
html body#mine div._hj_feedback_container div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles div._hj-1Fv4C__Feedback__container div#_hj_feedback_container._hj-1qvel__Feedback__feedback div._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-uSKNz__MinimizedWidgetMiddle__left button._hj-21t0-__MinimizedWidgetMiddle__label div._hj-1uQd9__MinimizedWidgetMiddle__text{
	font-family: 'Josefin Sans', sans-serif !important;
	font-size: .7rem !important;
	font-weight: 400 !important;
	letter-spacing:1px !important;
	text-transform: uppercase !important;	
}
html body#mine div._hj_feedback_container div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles div._hj-1Fv4C__Feedback__container div#_hj_feedback_container._hj-1qvel__Feedback__feedback div._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-uSKNz__MinimizedWidgetMiddle__left button._hj-21t0-__MinimizedWidgetMiddle__label{
	border-radius: 0 12px 12px 0;		
	padding: 15px 14px 15px 15px;
	transform: unset;
	opacity:1;
}
html body#mine div._hj_feedback_container div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles div._hj-1Fv4C__Feedback__container div#_hj_feedback_container._hj-1qvel__Feedback__feedback div._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-uSKNz__MinimizedWidgetMiddle__left button._hj-21t0-__MinimizedWidgetMiddle__label:hover{
	background: #004be0 !important;
	box-shadow: unset;
}
