@import "./animate.css";
@import "Tools.scss";
@import "Header.scss";
@import "HomeNav.scss";
@import "PerCompany.scss";
@import "Footer.scss";
@import "HotjatWidget.scss";

body,
html {
  overflow-x: hidden;
  font-size: 17.6px;
  margin: 0;
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
    margin: 0;
    line-height: 1.17;
  }
  a {
    line-height: 1;
    outline: 0;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      -webkit-transition: width 0.2s ease, background-color 0.2s ease;
      -o-transition: width 0.2s ease, background-color 0.2s ease;
      transition: width 0.2s ease, background-color 0.2s ease;
    }
  }
  p {
    padding-bottom: 1rem;
  }
  p,
  span {
    line-height: 1.69;
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: normal;
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      padding: 0;
    }
  }
  img {
    width: 100%;
  }
}
body#mine {
  color: #1d1d1f;
  background: #fcfcfc;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  letter-spacing: normal;
  .lax {
    transition: 9s;
  }
  .container {
    .and {
      padding: 0 7px;
      img {
        display: inline-flex;
      }
    }
    ul {
      display: table;
    }
    .project-info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      .logo {
        display: table;
        img {
          border: 0;
          border-radius: 0;
        }
      }
      .role {
        @include secondary-text;
        padding-top: 15.6px;
      }
      .duration {
        padding: 37px 0 2px;
        &.second-date {
          padding-top: 4px;
        }
      }
    }
    h2 {
      color: #1d1d1f;
      font-size: 1.72rem;
      font-weight: 500;
      text-transform: uppercase;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-left: 200px;
      padding-bottom: 0px;
      padding-top: 180px;
      span {
        line-height: 1;
        text-align: left;
      }
      #ui {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 2.3rem;
        font-size: 2.14rem;
        span:nth-child(1) {
          padding-left: 4.6px;
          padding-left: 3.2px;
          margin-bottom: -3px;
          margin-bottom: -4.3px;
        }
        span:nth-child(2) {
          font-size: 3.6rem;
          .mobile {
            display: none;
          }
        }
      }
      .and {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: end;
        align-self: flex-end;
        padding: 0 10px 5px 8px;
        img {
          width: 28px;
          height: 32px;
        }
      }
      #fe {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-item-align: end;
        align-self: flex-end;
        padding-bottom: 3px;
      }
    }

    /*MAIN*/
    .main {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      min-height: 100%;
      #heading-wrapper {
        padding-bottom: 90px;
      }
      div {
        .golden {
          -webkit-box-shadow: unset;
          box-shadow: unset;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 78px 0 27px 0;
          .golden-left {
            opacity: 0.8;
            width: 100%;
            max-width: 420px;
            padding-left: 207px;
          }
          .golden-right {
            background-size: 100%;
            opacity: 0.8;
            width: 100%;
            max-width: calc(93.4% - 427px);
            padding-left: 63px;
          }
        }
        .golden.main-description-wrapper {
          border-top: #1d1d1f solid 1px;
          border-bottom: #1d1d1f solid 1px;
          padding: 78px 0 68px 0;
        }
      }
      .description {
        font-family: "Nunito", sans-serif;
        font-family: "Lexend", arial, sans-serif;
        font-weight: 300;
        font-size: 0.945rem;
        text-align: left;
        padding-right: 45px;
        h3 {
          font-family: "Playfair Display", serif;
          font-weight: 500;
          font-size: 1.74rem;
          padding-bottom: 36px;
          .dot {
            font-family: "Josefin Sans", sans-serif;
            font-size: 1.15rem;
            line-height: 1;
            padding: 0 3.6px;
          }
        }
        h4 {
          position: relative;
          font-family: "Playfair Display", serif;
          font-weight: 500;
          font-size: 1.35rem;
          padding: 17px 0 9px;
        }
        p span.category-sub-heading {
          font-family: "Playfair Display", serif;
          font-weight: 500;
          font-size: 1.35rem;
        }
        p {
          max-width: 620px;
          .highlight {
            font-weight: 400;
          }
          .emoji {
            vertical-align: middle;
          }
          .arrow {
            vertical-align: sub;
            display: none;
          }
        }
        p.secondary-paragraph {
          // max-width: 640px;
        }
        p.wildcard-b {
          max-width: 696px;
        }
        p.paragraph-block {
          padding: 5.6px 0 12px;
          span {
          }
        }
        ul {
          padding: 0;
          li {
            -webkit-box-shadow: unset;
            box-shadow: unset;
            margin: 0;
            padding-bottom: 0.7rem;
            p {
              padding: 0;
            }
          }
        }
        ul li p:before {
          content: "";
          position: absolute;
          width: 4.2px;
          height: 4.2px;
          background-color: #04c;
          border-radius: 50%;
          top: 14.2px;
          left: -15px;
        }
      }
      .list-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        ul.floating-list {
          width: 100%;
          position: absolute;
          li {
            -webkit-box-shadow: unset;
            box-shadow: unset;
            margin: 0 0 70px 0;
          }
        }
      }
      ul {
        margin: 0 auto;
        padding: 0;
        max-width: 1284px;
        li.golden {
          -webkit-box-shadow: unset;
          box-shadow: unset;
          margin-bottom: calc(5% - 30px);
        }
        li.two-columns,
        li.three-columns {
          -webkit-box-shadow: unset;
          box-shadow: unset;
          img {
          }
        }
        #list-inner-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
        li.two-columns {
          .fifty {
            width: 100%;
            max-width: 50%;
            align-items: center;
            justify-content: center;
            img {
              -webkit-box-shadow: 0px 11px 5px -7px #f1f2f8;
              box-shadow: 0px 11px 5px -7px #f1f2f8;
            }
            .description {
              padding: 0 90px;
            }
          }
          .fifty.right {
            position: relative;
            img.pen {
              position: absolute;
              right: -16px;
              top: 95px;
              border: 0;
              -webkit-box-shadow: unset;
              box-shadow: unset;
              max-width: 70px;
            }
            .fifty.left {
            }
          }
        }
        li.two-columns.centered {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          .fifty {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }
        li.three-columns {
          #list-inner-wrapper .one-third {
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
            img {
              -webkit-box-shadow: unset;
              box-shadow: unset;
            }
          }
        }
        li.userflow {
          img {
          }
        }
        li.ux {
          .fifty img,
          .one-third img {
            -ms-flex-item-align: start;
            align-self: flex-start;
          }
          div.fifty.right {
            padding-right: 12.5px;
          }
          div.fifty.left {
            padding-left: 12.5px;
            .description {
              padding: 0 81px 0 77.5px;
            }
          }
        }
        li.ux.user-journeys {
        }
        li.ux.a {
          margin-bottom: 25px;
        }
        li.wireframe {
          border-radius: 0 16px 16px 0;
          img {
            border-radius: 0 16px 16px 0;
          }
        }
        li.question {
          img#question-mark {
            position: absolute;
            max-width: 200px;
            border: 0;
            top: calc(50% - 90px);
            left: calc(50% - 100px);
          }
        }
        li {
          position: relative;
          float: left;
          -webkit-box-shadow: 0px 11px 5px -7px #f1f2f8;
          box-shadow: 0px 11px 5px -7px #f1f2f8;
          border-radius: 16px;
          line-height: 0;
          width: 100%;
          margin: 0 0 3.6% 0;
          .industry {
            @include secondary-text;
            font-size: 0.74rem;
            position: relative;
            color: #04c;
            float: right;
            padding: 0 3px 0 0;
          }
          .industry:before {
            content: "";
            position: absolute;
            width: 9px;
            height: 9.5px;
            background-color: #ccddff;
            bottom: 7px;
            left: -16px;
            border-radius: 1px;
          }
          img {
            border: 1px solid #f1f8ff;
            border-radius: 16px;
            width: 100%;
            height: auto;
          }
          .info-block {
            .company-name {
              display: none;
            }
            p {
              text-align: left;
              font-size: 1rem;
              padding: 0 10px 1px 33px;
              span {
                -ms-flex-item-align: end;
                -ms-grid-row-align: end;
                align-self: end;
                &.dash {
                  display: none;
                }
                &.product-name {
                  position: relative;
                  font-size: 1.62rem;
                  font-weight: 500;
                  &:before {
                    @include deliverable-highlight;
                  }
                }
                &.work {
                  @include secondary-text;
                  letter-spacing: unset;
                  padding: 0 12px 4px 16px;
                }
                &.not-my-design {
                  @include secondary-text;
                  letter-spacing: unset;
                  font-weight: 300;
                  padding-bottom: 5px;
                }
              }
            }
          }
          .oldie-wrapper {
            display: none;
            @include secondary-text;

            position: absolute;
            top: 15px;
            width: 100%;
            color: #ababab;
            float: right;
            font-size: 0.6rem;
            letter-spacing: unset;
            text-align: center;
            text-transform: uppercase;
            .oldie {
              position: relative;
              line-height: 1;
            }
            .oldie:before {
              content: "";
              position: absolute;
              width: 9px;
              height: 9px;
              background-color: #04c;
              bottom: 2.5px;
              left: -16px;
              opacity: 0.5;
              border-radius: 1px;
            }
          }
          .oldie-wrapper#oldie-store {
            top: 18px;
            color: #bfbfbf;
          }
          &.mobile {
            -webkit-box-shadow: unset;
            box-shadow: unset;
            width: 33.33%;
            margin: 0 0 4% 0;
            div.info-block {
              padding: 0 20px 15px 20px;
              p {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                span.product-name {
                  width: 100%;
                }
                span.product-name:before {
                  top: 25.5px;
                }
                span.work {
                  padding: 0;
                }
                span.not-my-design {
                  padding: 0;
                }
                span:last-child {
                  padding-bottom: 10px;
                }
              }
            }
            div {
              img {
                border: 0;
                max-width: 306px;
                &.only-mobile {
                }
              }
            }
          }
          &.mobile.smaller-screen {
            display: none;
          }
          #fish {
            position: relative;
            z-index: 1;
            width: 180px;
            height: 207px;
            top: 2000px;
            .lax {
              border: 0;
              width: 160px;
              height: 187px;
              transition: unset;
            }
          }
          .floating-mobile {
            position: absolute;
            border: 0 !important;
            max-width: 300px;
            left: 0;
            z-index: 1;
          }
          .floating-mobile.a {
            top: 100px;
            top: 5000px;
            left: 114px;
          }
          .floating-mobile.b {
            left: unset;
            right: 0px;
            top: 360px;
            top: 4880px;
          }
          .floating-mobile.c {
            left: 100px;
            top: 100px;
          }
          .floating-mobile.d {
            top: 100px;
            top: 1000px;
            left: unset;
            right: 45px;
          }
          .floating-mobile.e {
            top: 0px;
            top: 2100px;
            left: 45px;
          }
          .floating-mobile.f {
            top: 1050px;
            left: unset;
            right: 30px;
          }
          .floating-mobile.g {
            top: 330px;
            left: 100px;
          }
        }
        .wireframe {
          min-height: 780px;
        }
        .two-columns.sketch {
          min-height: 610px;
        }
        .question {
          min-height: 856px;
        }
        #hemp-tailor-product-page-ui {
          min-height: 525px;
        }
        .user-journey.a {
          min-height: 1528px;
        }
        .user-journey.b {
          min-height: 1321px;
        }
        .userflow {
          min-height: 1025px;
        }
        .ux.a {
          /*					min-height: 436px;*/
        }
        .ux.b {
          min-height: 677px;
        }
        #vendo-comparison {
          display: none;
          min-height: 1141px;
        }
        #vendo-reporting-revenue {
          min-height: 3141px;
        }
        #vendo-form-k {
          min-height: 804px;
        }
        #vendo-form-j {
          min-height: 1004px;
        }
        #vendo-form-g {
          min-height: 1185px;
        }
        #vendo-form-a {
          min-height: 789px;
        }
        #vendo-form-e {
          min-height: 1191px;
        }
        #vendo-form-h {
          min-height: 958px;
        }
        #vendo-form-f {
          min-height: 875px;
        }
        #vendo-store {
          min-height: 2006px;
        }
        #vendo-form-m {
          min-height: 1770px;
        }
        #vendo-form-n {
          min-height: 1034px;
        }
        #vendo-form-o {
          min-height: 1496px;
        }
        #vendo-form-p {
          min-height: 916px;
        }
        #vendo-reporting-interface {
          min-height: 1114px;
        }
        #vendo-form-q {
          min-height: 1366px;
        }
        #vendo-form-b {
          min-height: 1231px;
        }
        #vendo-form-d {
          min-height: 1066px;
        }
        #vendo-form-l {
          min-height: 808px;
        }
        #adnovation-landing-page {
          min-height: 2239px;
        }
        #adnovation-form-validation {
          min-height: 886px;
        }
        #adnovation-login {
          min-height: 673px;
        }
        #adnovation-outer-list {
          -webkit-box-shadow: unset;
          box-shadow: unset;
          margin-bottom: 0;
          #adnovation-inner-list {
            max-width: unset;
            display: table;
            li {
              width: 50%;
              -webkit-box-shadow: unset;
              box-shadow: unset;
            }
          }
        }
      }
    }
  }
}

/* QUERIES */
@media (max-width: 1284px) {
  body#mine div#root div.container div div.main {
    ul.floating-list {
      display: none;
    }
    div ul {
      padding: 0 20px;
      li {
        min-height: unset;
      }
    }
    div#vendo div.golden div.golden-right div.description ul {
      padding: 0;
      li {
        margin: 0;
      }
    }
    div ul li.mobile.smaller-screen {
      display: block;
      padding: 0 15px;
    }
    div#hemptailor div.golden div.golden-right div.description ul li {
      margin: 0;
    }
    div#adnovation ul li#adnovation-outer-list {
      margin: 0 0 70px 0;
    }
  }
  body#mine .container #thanku {
    border-top: 126px solid transparent;
    border-right: 1284px solid #f1f8ff;
    margin-top: 0;
  }
  body#mine .container #thanku-wrapper,
  body#mine .container #website-info {
    background: #f1f8ff;
  }
}

@media (max-width: 1020px) {
  body#mine div#root div.container div div.main {
    div .golden {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      .golden-right {
        max-width: unset;
        padding: 32px 0 0 208px;
      }
    }
    #vendo div.golden.user-interface div.golden-left {
      display: none;
    }
    #hemptailor {
      ul li.redesign {
        display: none;
      }
    }
    ul {
      li {
        -webkit-box-shadow: unset;
        box-shadow: unset;
        margin-bottom: 50px;
        .right img,
        .left img,
        #list-inner-wrapper .one-third img {
          -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
        }
      }
      li.two-columns {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        .fifty {
          max-width: unset !important;
          .description {
            padding-top: 75px;
          }
        }
        .fifty.right {
          max-width: 98%;
        }
      }
      li.three-columns.ux {
        #list-inner-wrapper {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;

          .one-third {
            padding: 0 0 43px 0;
            margin: 0;
            img {
              -webkit-box-shadow: 0px 11px 5px -7px #f1f2f8;
              box-shadow: 0px 11px 5px -7px #f1f2f8;
            }
            &:last-child {
              padding: 0;
            }
          }
        }
      }
      li.two-columns.ux {
        margin: 0;
        #list-inner-wrapper {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        div.fifty.right,
        div#list-inner-wrapper div.fifty.right,
        div.fifty.left,
        div#list-inner-wrapper div.fifty.left {
          padding: 0 0 43px 0;
        }
        .fifty .description {
          padding: 20px 90px 16px 188px;
        }
      }
      li.mobile.smaller-screen div.info-block {
        padding: 0 10px;
        p {
          max-width: 200px;
          margin: 0 auto;
          padding: 0;
          span {
            width: 100%;
          }
          ul
            li#vendo-form-j
            div.info-block
            ul
            li.mobile.smaller-screen
            div.info-block
            span.work {
            padding: 5px 0 0 0;
          }
          span.not-my-design {
            padding-top: 2px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  html body#mine div#root div.container div nav {
    padding-top: 60px;
    ul {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 325px;
      li {
        max-width: unset;
        margin: 0 auto 25px;
        a div.project-info {
        }
      }
    }
  }
  html body#mine div#root div.container .next-project {
    margin-top: -320px;
  }
  html body#mine div#root div.container div h2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    padding: 205px 20px 0px 20px;
    max-width: 325px;
    margin: 0 auto;
    #ui {
      span:nth-child(2) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .mobile {
        display: block;
      }
    }
    .and {
      display: none;
    }
    #fe {
      width: 100%;
      padding-left: 3px;
    }
  }
  body#mine div#root div.container .main div .golden.main-description-wrapper {
    padding: 84px 25px 25px;
    border-bottom: 0;
  }
  body#mine
    div#root
    div.container
    .main
    div#vendo
    div.golden.main-description-wrapper {
    padding: 84px 25px 0;
  }
  body#mine div#root div.container div div.main {
    div div.golden {
      padding: 0 25px 25px;
      .golden-left {
        padding: 0;
      }
      .golden-right {
        padding: 32px 0 0;
        .description {
          padding-right: 0;
        }
      }
    }
    ul li {
      .info-block .long-text {
        max-width: unset;
        span {
          width: 100%;
          display: block;
          padding-left: 0;
        }
      }
    }
    ul li.two-columns .fifty .description {
      padding: 65px 2px 0 5px;
    }
    ul li.two-columns.ux .fifty .description {
      padding: 10px 90px 43px 90px;
    }
    ul li.two-columns.ux {
      .fifty .description {
        padding: 20px 90px 16px 188px;
      }
    }
  }
  body#mine
    div#root
    div.container
    .main
    div#hemptailor
    ul
    li.two-columns.sketch,
  body#mine
    div#root
    div.container
    .main
    div#adnovation
    ul
    li.adnovation-landing-page {
    padding-top: 0;
  }
  body#mine
    div#root
    div.container
    div
    #UserInterfaceDesignAndFrontEndDevelopment.main {
    div ul {
      li div.slideshow div.slideshow-navigation {
        top: unset;
        bottom: 50px;
        left: 50px;
      }
      li.question img#question-mark {
        max-width: 70px;
        border: 0;
        top: calc(50% - 25px);
        left: calc(50% - 35px);
      }
      li.two-columns.ux .fifty .description {
        width: 100%;
        padding: 20px 2px 43px 2px;
      }
      li.two-columns.ux.a {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
      }
      li .oldie-wrapper {
        display: none;
      }
      li.mobile.smaller-screen {
        width: 100%;
        text-align: center;
        div.info-block {
          p {
            span.work {
            }
            span.not-my-design {
            }
          }
        }
      }
      li.mobile.smaller-screen.wildcard {
        display: none;
      }
    }
  }

  @media (max-width: 680px) {
    body#mine div#root div.container header {
      border-top: 228px solid #fcfcfc;
      border-right: 324px solid transparent;
    }
    html body#mine div#root div.container .next-project {
      display: none;
    }
    html body#mine div#root div.container div nav {
      #binary {
        display: none;
      }
      ul li {
        border-top: 1px solid #def;
        border-right: 2px solid #def;
        border-bottom: 2px solid #def;
        border-left: 1px solid #def;
        -webkit-box-shadow: 12px 12px 16px 0 #fff inset,
          -7px -7px 36px 0px #f1f8ff;
        box-shadow: 12px 12px 16px 0 #fff inset, -7px -7px 36px 0px #f1f8ff;
        -webkit-box-shadow: 12px 12px 16px 0 #fff inset, 0 11px 5px -7px #def;
        box-shadow: 12px 12px 16px 0 #fff inset, 0 11px 5px -7px #def;
        a {
          border: 0;
          padding: 30px 20px 20px 20px;
          p {
            display: none;
          }
        }
      }
    }
    body#mine
      div#root
      div.container
      div
      #UserInterfaceDesignAndFrontEndDevelopment.main
      div
      ul
      li.two-columns.ux
      .fifty
      .description {
      padding: 20px 2px 0 2px;
    }
    html body#mine div#root div.container div div.main {
      div#vendo div.golden.user-interface div.golden-right {
        padding: 0;
      }
      #heading-wrapper {
        border: 0;
        padding-bottom: 142px;
        h2 {
          display: none;
        }
      }
      div ul li div.info-block {
        p {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          max-width: 204px;
          padding: 0;
          span {
            width: 100%;
          }
          span.product-name:before {
            display: none;
          }
          span.work {
            padding: 5px 0 0 0;
          }
          span.not-my-design {
            padding: 2px 0 0 0;
          }
          span:last-child {
            padding-bottom: 10px;
          }
        }
      }
      div#vendo ul li.user-journey div.info-block p {
        max-width: unset;
      }
      ul li img {
        border-radius: 6px;
        &.pen {
          top: 45px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    body#mine div#root div.container header div#header-container {
      ul li a a {
        font-size: 0.711rem;
      }
      a.contact {
        top: 24px;
        right: 0;
        background-color: rgba(0, 68, 204, 0.9);
        color: #fff;
        border: 0;
        border-radius: 20px 0 0 20px;
        padding: 10px 10px 7px 12px;
      }
    }
    html body#mine div#root div.container div h2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 100%;
      padding: 205px 20px 0px 20px;
      #ui {
        span:nth-child(2) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
        .mobile {
          display: block;
        }
      }
      .and {
        display: none;
      }
      #fe {
        width: 100%;
        padding-left: 3px;
      }
    }
    body#mine div#root div.container div div.main {
      ul li.two-columns .fifty.right img.pen {
        max-width: 45px;
      }
    }
    body#mine
      div#root
      div.container
      div
      #UserInterfaceDesignAndFrontEndDevelopment.main {
      h3 {
      }
      h4 {
      }
      div ul {
        li div.slideshow div.slideshow-navigation {
          bottom: 25px;
          left: 25px;
          .arrows {
            font-size: 0.77rem;
            img {
              width: 65px;
              height: 65px;
            }
            span {
              padding: 0 30px 0 20px;
            }
          }
        }
        li.mobile.smaller-screen {
          padding: 0;
          img {
            max-width: unset;
          }
          div.info-block p {
            span.work {
            }
            span.not-my-design {
            }
          }
        }
      }
    }
    body#mine div#root div.container #thanku {
      border-top: 90px solid transparent;
      border-right: 500px solid #f1f8ff;
      margin-top: 20px;
    }
    body#mine div#root div.container #thanku-wrapper h3 {
      max-width: 180px;
      margin: auto;
    }
    body#mine div#root div.container #thanku-wrapper {
      background: #f1f8ff;
      padding: 55px 20px 90px;
    }
    body#mine div#root div.container #top {
      display: none;
    }
    /*FEEDBACK WIDGET*/
    html
      body#mine
      div._hj_feedback_container
      div._hj-1oVIF__styles__globalStyles._hj-1_39t__styles__resetStyles
      div._hj-1Fv4C__Feedback__container
      div#_hj_feedback_container._hj-1qvel__Feedback__feedback
      div._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-uSKNz__MinimizedWidgetMiddle__left
      button._hj-21t0-__MinimizedWidgetMiddle__label {
      padding: 12px 10px;
      width: 30px;
    }
  }
  @media (max-width: 410px) {
    body#mine
      div#root
      div.container
      div
      #UserInterfaceDesignAndFrontEndDevelopment.main
      h3
      .dot {
      padding: 0 110.6px 0 3.6px;
    }
  }
}

#ray {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0px;
  svg {
    position: absolute;
    /*		opacity: .19;*/
    filter: blur(7.8px);
    /*				filter: blur(0px) !important;*/
    stroke-width: 0.05px;
  }
  svg#a {
    fill: #30d5c8;
    fill: #2cfed3;
    width: 210px;
    top: -120px;
    right: 35px;
    fill: rgba(44, 254, 211, 0.16);
    stroke: rgba(44, 254, 211, 1);
  }
  svg#b {
    fill: #a5f2ae;
    width: 180px;
    top: -50px;
    right: 196px;
    fill: rgba(165, 242, 174, 0.16);
    stroke: rgba(165, 242, 174, 1);
  }
  svg#c {
    fill: #94f09f;
    width: 60px;
    top: 26px;
    right: 265px;
    fill: rgba(83, 255, 255, 0.16);
    stroke: rgba(148, 240, 159, 1);
  }
  svg#d {
    fill: #a5f6bf;
    width: 110px;
    top: 100px;
    right: 460px;
    fill: rgba(165, 246, 191, 0.16);
    stroke: rgba(165, 246, 191, 1);
  }
  svg#e {
    fill: #ffffb1;
    width: 100px;
    top: 120px;
    right: 500px;
    fill: rgba(255, 255, 177, 0.16);
    stroke: rgba(255, 255, 177, 1);
    filter: blur(1px);
  }
  svg#f {
    fill: #c3ffda;
    width: 110px;
    top: 200px;
    right: 620px;
    fill: rgba(195, 255, 218, 0.16);
    stroke: #c3ffda;
    filter: blur(3px);
  }
  svg#g {
    fill: #98ecbc;
    width: 70px;
    top: 250px;
    right: 695px;
    fill: rgba(195, 255, 203, 0.16);
    stroke: #98ecbc;
    filter: blur(10px);
  }
  svg#h {
    fill: #fd8de1;
    width: 100px;
    top: 270px;
    right: 730px;
    fill: rgba(253, 141, 225, 0.16);
    stroke: #fd8de1;
  }
  svg#i {
    fill: #fd8de1;
    fill: #7307f2;
    width: 130px;
    top: 285px;
    right: 770px;
    fill: rgba(115, 7, 242, 0.16);
    stroke: #7307f2;
  }
  svg#j {
    fill: #2cf4f1;
    width: 160px;
    top: 310px;
    right: 810px;
    fill: rgba(44, 244, 241, 0.16);
    stroke: #2cf4f1;
    filter: blur(5px);
  }
  svg#k {
    fill: #085aff;
    width: 220px;
    top: 355px;
    right: 880px;
    fill: rgba(8, 90, 255, 0.16);
    stroke: #085aff;
    filter: blur(10px);
  }
}

#al {
  max-width: 270px;
  background: url("/../../img/wave.png");
  background-position: center;
  border-radius: 50%;
}

#binary {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0px;

  width: 100%;
  div {
    position: absolute;
    width: 100%;
    top: 0;
    &.wildcard {
      top: 306px;
    }
  }
}

.yay {
  top: 200px !important;
}

/* MODIFICATION start*/
body#mine .container {
  h2 {
    padding-top: 174px;
    //two
    padding-top: 142px;
  }
  nav {
    padding-top: 110px;
    ul li a {
      padding: 37px 21.8px 12px 26px;
      div.project-info .role-container {
        display: none;
      }
    }
  }
}
body#mine .container .project-info .role {
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  font-weight: 390;
  padding-top: 10px;
}
@media (max-width: 680px) {
  html body#mine div#root div.container div nav {
    ul li {
      a {
        p {
          display: block;
        }
      }
    }
  }
}
/* MODIFICATION end*/
html
  body#mine
  div#root
  div.container
  div
  div#UserInterfaceDesignAndFrontEndDevelopment.main.animate__animated.animate__fadeIn.animate__delay-14s.animate__slower
  div#vendo
  ul.animate__animated.animate__fadeIn.animate__slower {
}

// Ps
body#mine .container {
  #vendo {
    .description {
      p {
        &:nth-child(3) {
          max-width: 680px;
        }
      }
    }
    .user-experience-research {
      p {
        max-width: 610px;
      }
      p:nth-child(5) {
      }
    }
    .user-interface {
      p {
        max-width: 680px;
        &:nth-child(3),
        &:nth-child(4) {
          max-width: 742px;
        }
      }
    }
  }
  #hemptailor {
    .description {
      p {
        max-width: 586px;
      }
      p.paragraph-block + ul {
        li {
          p {
            &:last-child {
              max-width: 562px;
            }
          }
          &:nth-child(2) {
            p {
              max-width: 544px;
            }
          }
        }
      }
    }
  }
  #GraphicDesign {
    #vendo {
      .description {
        p {
          &:nth-child(3) {
            max-width: unset;
          }
        }
      }
      .description {
        p {
          &:last-child {
          }
        }
      }
    }
  }
}
