@import "./animate.css";
@mixin secondary-text {
  font-family: "Josefin Sans", sans-serif;
  font-size: 0.682rem;
  font-weight: 390;
  /*	letter-spacing:1.6px;*/
  letter-spacing: 1px;
  text-transform: uppercase;
}

body#mine {
  .container {
    header div#header-container ul {
      li.underline-uife {
        /*				text-decoration: none !important;*/
      }
      li.underline-gd {
        /*				text-decoration: underline !important;*/
      }
    }
    /*MAIN*/
    #GraphicDesign.main {
      border-top: 1px solid #000;
      margin-top: 135px;
      padding-top: 126px;
      .visual-bg {
        position: absolute;
        max-width: 1436.08px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 545px;
        font-weight: 500;
        top: -207px;
        z-index: 0;
        opacity: 0.016;
        color: #04c;
      }
      h1 {
        background: #fcfcfc;
        font-weight: 500;
        text-transform: uppercase;
        display: table;
        margin: -51px auto 70px;
        margin: -192px auto 24px;
        padding: 0 7px 14px;
        max-width: 217px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        span {
          width: 100%;
          height: 100%;
          padding: 0 1.2px;
          font-size: 3.4rem;
          background: url(alpha.jpg);
          background-size: 55px;
          background-position: center;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        span#v {
          font-size: 3.4rem;
          padding: 31.55px 1.2px 0 0;
          margin-left: -8px;
        }
        span#i {
          padding-top: 23.55px;
        }
        span#s {
          padding-top: 15.55px;
        }
        span#u {
          padding-top: 7.55px;
        }
        span#a {
          margin-top: -1.55px;
        }
        span#l {
          margin-top: -9.5px;
        }
        span#stars {
          font-size: 1.1rem;
        }
      }
      .description {
        max-width: 668px;
        text-align: center;
        margin: 0 auto;
        padding: 37px 20px;
        h3 {
          font-size: 1.8rem;
          padding-bottom: 16px;
        }
        p {
          padding-bottom: 0;
        }
      }
      p.visual-item-name {
        font-weight: 500;
        font-size: 1.62rem;
        .and {
          padding: 0;
          img {
            max-width: 15px;
          }
        }
      }
      .list-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        ul.floating-list {
          li#a {
            min-height: 2600px;
          }
          li div#fish {
            top: 0;
          }
        }
      }
      .boing {
        display: table;
        width: 100%;
        img {
          width: 100%;
          display: block;
          margin-bottom: -1px;
        }
      }
      #boing-a {
        padding-top: 9px;
      }
      .boing.b {
        position: relative;
        z-index: 1;
        margin-top: -183px;
      }
      #vendo {
        ul li.two-columns {
          margin-top: 77px;
          .fifty img {
            -webkit-box-shadow: unset;
            box-shadow: unset;
          }
        }
        ul {
          li.two-columns#vpc {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            .fifty.left div.logo img {
              min-width: 186px;
            }
          }
          .fifty.left {
            border: #d3edfd solid 1px;
            padding: 100px;
            div.logo {
              border: #d3edfd solid 1px;
              background: #fff;
              width: 100vw;
              height: 100vw;
              max-width: 450px;
              max-height: 450px;
              margin: auto;
              border-radius: 50%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                border: 0;
                width: 100%;
                max-width: 200px;
              }
            }
          }
          .fifty.right {
            p {
              padding: 0;
            }
          }
        }
        #vendo-media {
          background: #d3edfd;
          p.visual-item-name {
            text-align: center;
            padding: 0 15px 16px 23px;
          }
          #vendo-media-container {
            border-bottom: #1d1d1f solid 1px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            max-width: 1250px;
            margin: 0 auto;
            padding-bottom: 30px;
            ul {
              li {
                background: #fff;
                box-shadow: unset;
                border-radius: 16px;
                width: 100%;
                padding: 35px;
                img {
                  border: 0;
                  border-radius: 5px;
                }
              }
            }
            ul#left {
              max-width: 30%;
              margin-right: 4%;
              li {
                margin: 0 0 13.334% 0;
              }
            }
            ul#right {
              max-width: 66%;
              li {
                margin: 0 0 6.062% 0;
              }
            }
          }
        }
      }
      #anabel-instagram {
        background: #d3edfd;
        padding-top: 70px;
        .description {
          padding-top: 0;
        }
        ul#social-media {
          li:first-child {
            border-radius: 16px 16px 0 0;
            overflow: hidden;
          }
          li {
            box-shadow: unset;
            margin: 0;
            padding: 0;
            &:first-child {
              border-top-right-radius: 16px;
              border-top-left-radius: 16px;
            }
            img {
              border: 0;
              border-radius: 0;
            }
          }
        }
      }
      div#miscelaneous {
        background: rgb(241, 248, 255);
        background: -moz-linear-gradient(
          180deg,
          rgba(241, 248, 255, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        background: -webkit-linear-gradient(
          180deg,
          rgba(241, 248, 255, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        background: linear-gradient(
          180deg,
          rgba(241, 248, 255, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f8ff",endColorstr="#ffffff",GradientType=1);
        position: relative;
        #checkboard {
          z-index: 1;
          position: absolute;
          top: -77%;
          width: 100%;
        }
        ul {
          z-index: 1;
          position: relative;
          li {
            div.fifty.left,
            div.fifty.right {
              padding: 100px;
            }
            img {
              max-width: 250px;
              border: 0;
              box-shadow: unset;
              margin: 0 auto;
              &#frida {
                border-radius: 50%;
              }
            }
          }
        }
      }
      div#thanku {
        border: 0;
        padding-top: 360px;
      }
      #thanku-wrapper {
        padding: 0 20px 70px;
        h3 {
          background: rgb(0, 68, 204);
          background: -moz-linear-gradient(
            90deg,
            rgba(0, 68, 204, 1) 0%,
            rgba(32, 164, 243, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            rgba(0, 68, 204, 1) 0%,
            rgba(32, 164, 243, 1) 100%
          );
          background: linear-gradient(
            90deg,
            rgba(0, 68, 204, 1) 0%,
            rgba(32, 164, 243, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0044cc",endColorstr="#20a4f3",GradientType=1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline;
          margin: 0 auto;
        }
      }
      #website-info {
      }
    }
  }
}

/* QUERIES */
@media (max-width: 1300px) {
  html body#mine div#root div.container div#GraphicDesign.main {
    .list-container {
      display: none;
    }
    #vendo-media {
      padding: 0 20px 0;
    }
    #anabel-instagram {
      padding-top: 70px;
      ul#social-media {
        padding: 0 20px;
      }
    }
    div#miscelaneous {
      #checkboard {
        top: -60%;
      }
    }
    #thanku {
      display: none;
    }
    #thanku-wrapper {
      background: transparent;
      padding-top: 12%;
    }
  }
}
@media (max-width: 1100px) {
  html body#mine div#root div.container div#GraphicDesign.main div#vendo ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    li.two-columns {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      justify-content: center;
      width: 100%;
      margin-top: 0;
      margin-bottom: 60px;
      div.fifty {
        max-width: unset;
        .visual-item-name {
          padding-top: 10px;
        }
      }
      div.fifty.left {
        padding: 0 100px;
        margin-top: 50px;
      }
      div.fifty.right {
        padding: 21px 0 0 0;
      }
    }
  }
  html body#mine div#root div.container div#GraphicDesign.main {
    #thanku-wrapper {
      padding-top: 0%;
    }
  }
}
@media (max-width: 900px) {
  html body#mine div#root div.container div#GraphicDesign.main {
    .visual-bg {
      display: none;
    }
    .description {
      text-align: left;
    }
    .boing.b {
      margin-top: -117px;
    }
    div#miscelaneous {
      ul li {
        div.fifty.left,
        div.fifty.right {
          padding: 20px;
        }
      }
    }
    #thanku-wrapper {
      padding-top: 20%;
    }
  }
  html
    body#mine
    div#root
    div.container
    div#GraphicDesign.main
    div#vendo
    div#vendo-media
    div#vendo-media-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    align-items: center;
    ul {
      margin: auto;
      li {
        margin-bottom: 36px;
      }
    }
    ul#left {
      max-width: 370px;
    }
    ul#right {
      max-width: 770px;
    }
  }
}
@media (max-width: 600px) {
  html body#mine div#root div.container div#GraphicDesign.main {
    padding-top: 225px;
    h2 {
      padding: 0 33px;
      margin-bottom: 23px;
      span#i {
        max-width: 12.5px;
      }
      span#ii {
        max-width: 14px;
      }
    }
    div#vendo ul {
      padding: 0;
      li.two-columns {
        div.fifty {
          .visual-item-name {
            font-size: 1.25rem;
          }
        }
        div.fifty.left {
          padding: 0;
          border: 0;
          margin-top: 30px;
          div.is {
            max-width: 375px;
            max-height: 375px;
            img {
              max-width: 180px;
            }
          }
        }
      }
      li.two-columns#vpc .fifty.left div.logo img {
        min-width: 160px;
      }
    }
    div#vendo div#vendo-media {
      .visual-item-name {
        font-size: 1.25rem;
        padding: 10px 15px 16px 23px;
        .and img {
          max-width: 13.2px;
        }
      }
      div#vendo-media-container ul li {
        padding: 15px;
      }
    }
    .boing.b {
      margin-top: -47px;
    }
    div#miscelaneous {
      #checkboard {
        top: -30%;
      }
      ul {
        li {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          align-items: center;
          div.fifty.right {
            padding: 0 0 60px 0;
          }
          div.fifty.left {
            padding: 0 0 50px 0;
          }
        }
      }
    }
    #thanku-wrapper {
      padding-top: 0;
    }
  }
}
@media (max-width: 500px) {
  html body#mine div#root div.container div#GraphicDesign.main {
  }
}

@media (max-width: 900px) {
  html body#mine div#root div.container div#GraphicDesign.main {
  }
}

html
  body#mine
  div#root
  div.container
  div#GraphicDesign.main.animate__animated.animate__fadeIn.animate__delay-1s.animate__slowerr
  p.visual-bg.xx {
  top: 9000px;
  opacity: 1;
  left: -64px;
  z-index: 1;
  font-size: 200px;
  writing-mode: vertical-lr;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: inherit;
  -webkit-text-fill-color: transparent;
  color: turquoise;
}
