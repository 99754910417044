body#mine .container .main{
	#hemptailor{
		.golden div.golden-right div.description ul{
			padding: 0;
		}	
		.golden .golden-left div.project-info div.logo{
			padding-top: 14.04px;
			img{
				width: 180px;
				height: 18.83px;
			}
		}
		

		ul{	
			li.question div.info-block p{
			}
			li.two-columns.sketch div.fifty.right,
			li.two-columns.personas.b.comparison div.fifty{ 
				display: block;
			}	
			li.two-columns.sketch{
				padding-top: 48px;
			}
		}
	}
	#vendo{
		/*				padding-top: 36px;*/
		.golden{
			.golden-left div.project-info div.logo{
				padding-top: 5px;
				img{
					max-width: 100px;
					max-height: 29.21px;	
				}
			}
		}		
		.golden.user-experience-research{
			div.golden-right div.description{
				p.paragraph-block.wildcard{
					padding: 16px 0 12px;
				}
				h3{
					padding-top: 46px;
				}				
			}
		}
					
		.golden.ui{
			border-top: 0;
			padding-top: 40px;
			h4{
				padding-top: 4px;
			}
		}
	}		
	#adnovation{	
		
		ul li.adnovation-landing-page{
			padding-top: 48px;
		}
		
		.golden{
			.golden-left div.project-info{
				div.logo{
					padding-top: 12px;
					img{
						width: 196px;
						height: 24.75px;
					}
				}
				.duration{
					padding: 35px 0 2px;
				}
			}
		}
	}			
}