.slideshow{
	position: relative;
}
.slideshow-navigation {
	position: absolute;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	top: 50%;
	left: calc(50% - 60px);
}
div#hemptailor ul li div.slideshow div.slideshow-navigation {
	/*	top: 31.44%;*/
}
.play-pause, .arrows {
	line-height: 1;
	display: table;
	cursor: pointer;
	margin: 0 15px 0 0;
}
.play-pause{
	display: none;
}
.arrows {
	background:rgba(255,255,255, 0.9);
	-webkit-box-shadow: 0px 10px 20px -10px rgba(0,68,204, 0.25);		
	box-shadow: 0px 10px 20px -10px rgba(0,68,204, 0.25);			
	border: #04c solid 1px;
	color: #000;
	font-weight: 500;
	text-transform: uppercase;								
	font-family: 'Josefin Sans', sans-serif;
	font-size: .92rem;
	letter-spacing:1.8px;
	line-height: 1;
	display: flex;
	cursor: pointer;
	border-radius: 70px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	justify-content: center;
	align-items: center;
}
.arrows span{
	padding: 0 36px 0 26px;
}	
body#mine .container .main ul li .slideshow-navigation .arrows img{	
	color: transparent;
	border: 0;
	border-radius: 0;
	width: 120px;
	height: 120px;
}
.arrows:hover {
	-webkit-box-shadow: 0px 10px 20px 0px rgba(0,68,204, 0.15);		
	box-shadow: 0px 10px 20px 0px rgba(0,68,204, 0.15);			
}		
#slides-count{
	display: none;
}

@media (max-width: 500px) {
	.slideshow-navigation {
	}
	body#mine .container .main ul li .slideshow-navigation .arrows img{	
	}
}