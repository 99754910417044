body#mine .container {
  #thanku {
    position: relative;
    border-top: 126px solid transparent;
    border-right: 1440px solid #fcfcfc;
    margin-top: -215px;
  }
  #thanku-wrapper {
    position: relative;
    background: #fcfcfc;
    width: 100%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 70px 20px;
    h3 {
      font-size: 1.62rem;
      padding-bottom: 30px;
    }
    span {
      vertical-align: text-top;
      padding-left: 2px;
    }
    #hello {
      @include secondary-text;

      font-size: 0.77rem;
      letter-spacing: unset;
      padding: 10px 0 20px;
    }
    a {
      @include secondary-text;

      color: #1d1d1f;
      border-radius: 20px;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: normal;
      display: table;
      margin: 0 auto;
      padding: 15px 33px 12px;
      -webkit-box-shadow: 0px 10px 20px -10px #ccddff;
      box-shadow: 0px 10px 20px -10px #ccddff;
      &:hover {
        color: #fff;
        background-color: #04c;
        box-shadow: unset;
        padding: 15px 23px 12px;
      }
    }
  }
  #top {
    position: fixed;
    right: 10px;
    bottom: 15px;
    z-index: 999;
    padding: 15px;
    a {
      display: block;
      transition: 0.9s;
      img {
        width: 18px;
        height: 12px;
      }
      &:hover {
        padding-bottom: 25px;
        transition: 1s;
      }
    }
  }
  .next-project {
    @include secondary-text;

    position: relative;
    border: #04c solid 1px;
    border-radius: 20px;
    color: #000;
    font-weight: 500;
    font-size: 0.7rem;
    letter-spacing: 1.72px;
    padding: 14px 115px 11px 15px;
    float: right;
    margin-bottom: 82px;
    margin-right: -106px;
    margin-top: -222px;
    &:hover {
      color: #fff;
      background: #04c;
      text-decoration: none;
    }
  }
  #website-info {
    background: #f1f8ff;
    text-align: center;
    padding: 7px;
    p {
      color: #505050;
      font-size: 0.82rem;
      padding: 0;
    }
  }
}
#GraphicDesign {
  div {
    #thanku-wrapper {
      a {
        color: #1d1d1f;
        @include secondary-text;
        border-radius: 20px;
        font-weight: 500;
        font-size: 0.77rem;
        letter-spacing: unset;
        display: table;
        margin: 0 auto 0;
        padding: 15px 33px 12px 33px;
        -webkit-box-shadow: 0px 10px 20px -10px #ccddff;
        box-shadow: 0px 10px 20px -10px #ccddff;
        &:hover {
          color: #fff;
          background-color: #04c;
          box-shadow: unset;
          padding: 15px 23px 12px 23px;
        }
      }
    }
  }
}
