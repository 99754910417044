body#mine .container nav{
	padding-top: 132px;
	ul{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		width: 100%;
		max-width: 1244px;
			//two
			max-width: 840px;
		margin: auto;
		li{
			width: 100%;
			max-width: 33.33%;
			margin: 0 15px;					
			border-radius: 16px 16px 0 0; 
			background: #fcfcfc;
			box-shadow: 12px 12px 16px 0 #fff inset, -8px -8px 12px 0 #f1f2f8;
			box-shadow: 12px 12px 16px 0 #fff inset, -7px -7px 36px 0px #f1f2f8;
			//two
			max-width: 390px;
			margin: 0 15px;
			.duration{
				font-size: 1.04rem;
			}		
			h3{
				padding: 33px 0 0 0;
				position: relative;
			}
			.logo{
				width: 100%;
			}
			&:hover{
				div.project-info{						
					.duration{
						color: #04c;
					}
				}				
			}
			a{
				color: #000;	
				text-decoration: unset;
				display: block;
				height: 100%;
				border-radius: 16px;
				transition: 0.1s;
				padding: 37px 21.8px 20px 26px;
				border: 1px solid transparent;
				&:hover{
					border: 1px solid transparent;
					border-left: 1px solid #f8fbff;
					-webkit-box-shadow: -7px -7px 16px #f1f8ff;				
					box-shadow: -7px -7px 16px #f1f8ff;							
				}					
				div.project-info{						
					.duration{
						padding: 24px 0 20px;
					}
					p{
						font-family: 'Nunito', sans-serif;	
						font-family: "Lexend", arial, sans-serif;		
						font-weight: 300;	
						font-size: .945rem;
					}
					.role-container{
						padding-top: 7px;
						.role{
							display: inline-block;
							float: left;
							box-shadow: 2px 2px #ddeeff;
							padding: 10px 17px;
							margin: 0 10px 10px 0;
							border-radius: 0px 0 20px 0;
						}
					}
				}
			}			
		}
		li:hover{				
		}
		li a:hover img {
			-webkit-filter: invert(50%) sepia(90%) saturate(9000%) hue-rotate(215deg) brightness(100%) contrast(100%);
			filter: invert(50%) sepia(90%) saturate(9000%) hue-rotate(215deg) brightness(100%) contrast(100%);
		}							
		li#vendo a{		
			padding-top: 28px;
			div.project-info div.logo img{			
				max-width: 100px;				
				max-height: 29.2167px;					
			}
		} 
		li#hemptailor a{					
			h3{
				padding: 35px 0 0 0;
			}
			div.project-info div.logo img{
				width: 180px;
				height: 18.8333px;
			} 
		}
		li#adnovation a{
			padding-top: 34px;
			h3{
				padding: 32px 0 0 0;
			}						
			div.project-info div.logo img{
				width: 196px;
				height: 24.75px;
			}
		} 
	}
}						