body#mine .container header {
  border-top: 273px solid #fcfcfc;
  border-right: 377px solid transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  #header-container {
    position: fixed;
    top: 0;
    left: 0;
    padding: 27px 25px 25px 25px;
    #name {
      a {
        text-decoration: none;
      }
    }
    a {
      color: #000;
      font-size: 1.33rem;
      /*			text-decoration: underline;*/
      &:hover {
        color: #04c;
        text-decoration: none;
        // text-decoration: underline;
      }
      .beta {
        color: #568fff;
        position: absolute;
        top: -6px;
        font-size: 0.82rem;
        font-style: italic;
        text-transform: lowercase;
        font-weight: 400;
        line-height: 1;
        padding-left: 6px;
      }
    }
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0;
      padding: 15.5px 0 0 1px;
      li {
        padding: 6px 15px 12px 0;
        a {
          @include secondary-text;

          // font-size: 0.8rem;
          letter-spacing: 1.5px;
          .and {
            padding: 0;
            img {
              width: 8px;
            }
          }
          &.active {
            /*								text-decoration: underline;*/
          }
        }
      }
      li.underline-uife {
        /*						text-decoration: underline !important;*/
      }
      li.underline-gd {
        /*						text-decoration: none !important;*/
        a {
          &:hover {
            // color: #1b969d;
            // background: url(alpha.jpg);
            // background-size: 66px;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    a.contact {
      @include secondary-text;
      position: fixed;
      top: 25px;
      right: 0;
      border: #04c solid 1px;
      border-radius: 20px;
      border-radius: 20px 0 0 20px;
      color: #000;
      font-weight: 500;
      font-size: 0.7rem;
      letter-spacing: 1.72px;
      padding: 14px 14px 11px 16px;
      color: #fff;
      background: #04c;
      &:hover {
        color: #fff;
        background: #04c;
        text-decoration: none;
        background: #004be0;
        box-shadow: unset;
      }
    }
    #marble {
      display: none;
      border-radius: 50%;
      img {
        width: 20px;
        height: 20px;
      }
      &#stylex {
        display: none;
      }
      &#english-version {
      }
      a:hover {
        -ms-transform: rotate(70deg);
        -webkit-transform: rotate(70deg);
        transform: rotate(70deg);
        transition-duration: 0.36s, 0.36s;
      }
    }
  }
}
