@mixin secondary-text {
	font-family: 'Josefin Sans', sans-serif;
	font-size: .682rem;
	font-size: .7rem;
	font-weight: 400;
	/*	letter-spacing:1.6px;*/
	letter-spacing: 1px;
	text-transform: uppercase;				
}
@mixin deliverable-highlight{
	content: "";
	width: 10px;
	height: 10px;
	background-color: #04c;
	border-radius: 50%;
	display: block;
	position: absolute;
	bottom: 7.5px;
	left: -20px;
}
